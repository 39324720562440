import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 968.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,968.000000) scale(0.100000,-0.100000)">
          
          
          <path d="M6990 9643 c-61 -11 -172 -48 -247 -84 -45 -21 -87 -39 -92 -39 -6 0
-11 -4 -11 -9 0 -5 -8 -12 -18 -15 -10 -3 -38 -24 -62 -47 -25 -22 -52 -46
-60 -52 -23 -18 -100 -113 -148 -183 -67 -99 -115 -224 -133 -349 -6 -41 -12
-435 -14 -965 -2 -553 -8 -934 -15 -997 -22 -210 -121 -421 -273 -583 -31 -34
-143 -125 -188 -155 -63 -40 -215 -104 -291 -122 -54 -13 -115 -18 -238 -18
-217 0 -293 16 -451 95 -134 67 -154 80 -239 161 -33 33 -65 59 -70 59 -13 0
0 -1053 14 -1066 26 -24 231 -90 371 -118 39 -8 138 -20 222 -27 184 -15 327
-5 573 42 71 14 309 93 357 119 21 11 42 20 47 20 32 0 396 238 396 259 0 4
12 14 28 23 25 15 176 163 221 218 12 14 30 36 42 50 160 192 295 473 358 745
7 28 19 79 28 115 16 59 17 200 20 1498 l4 1432 -53 -1 c-29 -1 -64 -4 -78 -6z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
